import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'USERLIST': {
            const { user_list } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user_list,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    userlist: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        const response = await axios.post(`${process.env.REACT_APP_API_HOST}/v0/application-admin/login`, {
            email,
            password,
        })
        
        console.log(response)
        if(response.data && response.data.statusCode === 200){
            const { token, user_info } = response.data.data

            setSession(token)
    
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: user_info,
                },
            })    
        }else{
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
        }
    }
    const userlist = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}/v0/application-admin/userlist`)
        
        console.log(response)
        if(response.data && response.data.statusCode === 200){
            const user_list = response.data.data

            dispatch({
                type: 'USERLIST',
                payload: {
                    user_list: user_list,
                },
            })    
        }else{
            dispatch({
                type: 'USERLIST',
                payload: {
                    user_list: [],
                },
            })
        }
    }
    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                
                if (accessToken) {
                    setSession(accessToken)
                    const headers = {
                        'Authorization': accessToken
                    }
                    const response = await axios.get(`${process.env.REACT_APP_API_HOST}/v0/application-admin/self-profile`,headers)
                    if(response.data && response.data.statusCode === 200){
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: true,
                                user: response.data.data,
                            },
                        })
                    }else{
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        })    
                    }                    
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                userlist,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
