import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CoinDistributionTable = Loadable(lazy(() => import('./CoinDistributionTable')));
const CoinDistributionDetail = Loadable(lazy(() => import('./CoinDistributionDetail')));

const transactionsRoutes = [
  {
    path: '/coin-distribution/list',
    element: <CoinDistributionTable />,
  },
  {
    path: '/coin-distribution/:id',
    element: <CoinDistributionDetail />,
  }
];

export default transactionsRoutes;
