import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const UserTable = Loadable(lazy(() => import('./UserTable')));
const UserDetail = Loadable(lazy(() => import('./UserDetail')));

const userRoutes = [
  {
    path: '/user/list',
    element: <UserTable />,
  },
  {
    path: '/user/:id',
    element: <UserDetail />,
  }
];

export default userRoutes;
