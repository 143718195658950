import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CustomPushNotificationTable = Loadable(lazy(() => import('./CustomPushNotificationTable')));

const postRoutes = [
  {
    path: '/custom/push',
    element: <CustomPushNotificationTable />,
  }
];

export default postRoutes;
