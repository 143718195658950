import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const GiftTable = Loadable(lazy(() => import('./GiftTable')));

const postRoutes = [
  {
    path: '/gift/list',
    element: <GiftTable />,
  }
];

export default postRoutes;
