import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PostTable = Loadable(lazy(() => import('./PostTable')));

const postRoutes = [
  {
    path: '/post/list',
    element: <PostTable />,
  }
];

export default postRoutes;
