import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import profileRoutes from 'app/views/profile/ProfileRoutes';
import userRoutes from 'app/views/user/UserRoutes';
import postRoutes from 'app/views/post/PostRoutes';
import eventRoutes from 'app/views/event/EventRoutes';
import customPushNotificationRoutes from 'app/views/customPushNotification/CustomPushNotificationRoutes';
import giftRoutes from 'app/views/gift/GiftRoutes';
import coinRoutes from 'app/views/coin/CoinRoutes';
import coinCouponRoutes from 'app/views/coinCoupon/CoinCouponRoutes';
import challengeRoutes from 'app/views/challenge/ChallengeRoutes';
import issueReportedRoutes from 'app/views/issueReported/IssueReportedRoutes';
import customFramesRoutes from 'app/views/customFrames/FrameRoutes';
import adminUserRoutes from 'app/views/adminUser/UserRoutes';
import transactionsRoutes from 'app/views/transactions/TransactionsRoutes';
import coinDistributionRoutes from 'app/views/coinDistribution/CoinDistributionRoutes';
import scheduledPushNotificationRoutes from 'app/views/scheduledPushNotification/ScheduledPushNotificationRoutes';
import categoryRoutes from 'app/views/category/CategoryRoutes';

import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes, ...profileRoutes, ...userRoutes, ...postRoutes, ...eventRoutes, ...customPushNotificationRoutes, ...giftRoutes, ...coinRoutes, ...challengeRoutes, ...chartsRoute, ...coinCouponRoutes, ...issueReportedRoutes, ...customFramesRoutes, ...adminUserRoutes, ...transactionsRoutes, ...coinDistributionRoutes, ...scheduledPushNotificationRoutes, ...categoryRoutes, ...materialRoutes],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  // { path: '*', element: <NotFound /> },
];

export default routes;
