import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ScheduledPushNotificationTable = Loadable(lazy(() => import('./ScheduledPushNotificationTable')));

const postRoutes = [
  {
    path: '/scheduled-push/list',
    element: <ScheduledPushNotificationTable />,
  }
];

export default postRoutes;
