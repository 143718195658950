export const navigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { name: 'Admin User Listing', path: '/admin-user/list', icon: 'group' },
  { name: 'User Listing', path: '/user/list', icon: 'group' },
  { name: 'Post Listing', path: '/post/list', icon: 'video_label' },
  { name: 'Event Listing', path: '/event/list', icon: 'video_label' },
  { name: 'Custom Push', path: '/custom/push', icon: 'video_label' },
  { name: 'Gifts Listing', path: '/gift/list', icon: 'video_label' },
  { name: 'Coins Listing', path: '/coin/list', icon: 'video_label' },
  { name: 'Coins Coupon Listing', path: '/coincoupon/list', icon: 'video_label' },
  { name: 'Challenges Listing', path: '/challenge/list', icon: 'video_label' },
  { name: 'Custom Frames Listing', path: '/custom-frames/list', icon: 'group' },
  { name: 'Transaction Listing', path: '/transactions/list', icon: 'attach_money' },
  { name: 'S-Notification Listing', path: '/scheduled-push/list', icon: 'video_label' },
  { name: 'Category Listing', path: '/category/list', icon: 'video_label' },
  { name: 'Coin Distribution', path: '/coin-distribution/list', icon: 'group' },
  { name: 'Issue Reported Listing', path: '/reported-issue/list', icon: 'group' },
  // { label: 'PAGES', type: 'label' },
  // {
  //   name: 'Session/Auth',
  //   icon: 'security',
  //   children: [
  //     { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
  //     { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
  //     { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
  //     { name: 'Error', iconText: '404', path: '/session/404' },
  //   ],
  // },
  // { label: 'Components', type: 'label' },
  // {
  //   name: 'Components',
  //   icon: 'favorite',
  //   badge: { value: '30+', color: 'secondary' },
  //   children: [
  //     { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
  //     { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
  //     { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
  //     { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
  //     { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
  //     { name: 'Form', path: '/material/form', iconText: 'F' },
  //     { name: 'Icons', path: '/material/icons', iconText: 'I' },
  //     { name: 'Menu', path: '/material/menu', iconText: 'M' },
  //     { name: 'Progress', path: '/material/progress', iconText: 'P' },
  //     { name: 'Radio', path: '/material/radio', iconText: 'R' },
  //     { name: 'Switch', path: '/material/switch', iconText: 'S' },
  //     { name: 'Slider', path: '/material/slider', iconText: 'S' },
  //     { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
  //     { name: 'Table', path: '/material/table', iconText: 'T' },
  //   ],
  // },
  // {
  //   name: 'Charts',
  //   icon: 'trending_up',
  //   children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  // },
  // {
  //   name: 'Documentation',
  //   icon: 'launch',
  //   type: 'extLink',
  //   path: 'http://demos.ui-lib.com/matx-react-doc/',
  // },
];
