import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'

const initialState = {
    isInitialised: false,
    user_list: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { user_list } = action.payload

            return {
                ...state,
                isInitialised: true,
                user_list,
            }
        }
        case 'USERLIST': {
            const { user_list } = action.payload

            return {
                ...state,
                user_list,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const UserContext = createContext({
    ...initialState,
    method: 'Application',
    userList: () => Promise.resolve(),
})

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const userList = async (email, password) => {
        const response = await axios.get(`${process.env.REACT_APP_API_HOST}/v0/application-admin/userlist`)
        
        console.log(response)
        if(response.data && response.data.statusCode === 200){
            const user_list = response.data.data

            dispatch({
                type: 'USERLIST',
                payload: {
                    user_list: user_list,
                },
            })    
        }else{
            dispatch({
                type: 'INIT',
                payload: {
                    user_list: [],
                },
            })
        }
    }

    useEffect(() => {
        ; (async () => {
            try {
                debugger
                const response = await axios.get(`${process.env.REACT_APP_API_HOST}/v0/application-admin/userlist`)
        
                console.log(response)
                if(response.data && response.data.statusCode === 200){
                    const user_list = response.data.data

                    dispatch({
                        type: 'USERLIST',
                        payload: {
                            user_list: user_list,
                        },
                    })    
                }else{
                    dispatch({
                        type: 'INIT',
                        payload: {
                            user_list: [],
                        },
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        user_list: [],
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <UserContext.Provider
            value={{
                ...state,
                method: 'Application',
                userList,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserContext
