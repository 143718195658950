import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const TransactionsTable = Loadable(lazy(() => import('./TransactionsTable')));
const TransactionsDetail = Loadable(lazy(() => import('./TransactionsDetail')));

const transactionsRoutes = [
  {
    path: '/transactions/list',
    element: <TransactionsTable />,
  },
  {
    path: '/transactions/:id',
    element: <TransactionsDetail />,
  }
];

export default transactionsRoutes;
