import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const SelfProfile = Loadable(lazy(() => import('./SelfProfile')));

const profileRoutes = [
  {
    path: '/self/profile',
    element: <SelfProfile />,
  }
];

export default profileRoutes;
