import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CoinCouponTable = Loadable(lazy(() => import('./CoinCouponTable')));

const postRoutes = [
  {
    path: '/coincoupon/list',
    element: <CoinCouponTable />,
  }
];

export default postRoutes;
