import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const IssueReportedTable = Loadable(lazy(() => import('./IssueReportedTable')));

const issueReportedTableRoutes = [
  {
    path: '/reported-issue/list',
    element: <IssueReportedTable />,
  }
];

export default issueReportedTableRoutes;
