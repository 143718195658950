import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CategoryTable = Loadable(lazy(() => import('./CategoryTable')));

const postRoutes = [
  {
    path: '/category/list',
    element: <CategoryTable />,
  }
];

export default postRoutes;
