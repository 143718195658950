import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const FrameTable = Loadable(lazy(() => import('./FrameTable')));

const postRoutes = [
  {
    path: '/custom-frames/list',
    element: <FrameTable />,
  }
];

export default postRoutes;
