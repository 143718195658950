import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CoinTable = Loadable(lazy(() => import('./CoinTable')));

const postRoutes = [
  {
    path: '/coin/list',
    element: <CoinTable />,
  }
];

export default postRoutes;
